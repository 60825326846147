import React, { useEffect } from 'react';
import { Form, Button, Input } from 'antd';
const WorkflowForm = ({ onSubmit, workflowName }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        onSubmit(values);
    }

    useEffect(() => {
        if (workflowName) {
            form.setFieldsValue({ workflow_name: workflowName });
        }
    }, [workflowName, form]);

    return (
        <div>
            <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ workflow_name: workflowName }}>

                <Form.Item name="workflow_name" label="Workflow Name" rules={[{ required: true, message: 'Please input workflow name' }]} >
                    <Input placeholder="Name your workflow" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
export default WorkflowForm;