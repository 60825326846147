exports.obj = function (username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}`] = {
        get: {
            tags: ['Supabase'],
            summary: 'Table Data',
            description: 'List data from a table',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'tableName',
                    in: 'query',
                    description: 'Name of table',
                    type: 'string',
                    required: true,
                },
                {
                    name: "schema",
                    in: "query",
                    description: "Schema of table, defaults to 'public'",
                    type: "string"
                },
                {
                    name: 'selectString',
                    in: 'query',
                    description: 'select query to customize the response',
                    type: 'string',
                },
                {
                    name:"filterFieldContains",
                    in:"query",
                    description:"eg: FieldName-contains-someValue",
                    type:"string"
                },
                {
                    name:"filterFieldEquals",
                    in:"query",
                    description:"eg: FieldName-equals-someValue",
                    type:"string"
                },
                {
                    name: 'page',
                    in: 'query',
                    description: 'Page number',
                    type: 'string',
                },
                {
                    name: 'perPage',
                    in: 'query',
                    description: 'Number of object in a page call.',
                    type: 'string',
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        post: {
            tags: ['Supabase'],
            summary: 'Add Data',
            description: 'To add new rows into the table',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'tableName',
                    in: 'query',
                    description: 'Name of table',
                    type: 'string',
                    required: true,
                },
                {
                    name: "schema",
                    in: "query",
                    description: "Schema of table, defaults to 'public'",
                    type: "string"
                },
                {
                    name: 'type',
                    in: 'body',
                    description: 'An array of object to add into table',
                    required: true,
                    sampleData: [
                        { name: 'james', email: 'james@example.com' },
                        { name: 'marry', email: 'marry@example.com' },
                    ],
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        put: {
            tags: ['Supabase'],
            summary: 'Update Data',
            description: 'To update data into the table',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'tableName',
                    in: 'query',
                    description: 'Name of table',
                    type: 'string',
                    required: true,
                },
                {
                    name: "schema",
                    in: "query",
                    description: "Schema of table, defaults to 'public'",
                    type: "string"
                },
                {
                    name: 'type',
                    in: 'body',
                    description:
                        'object data with matchObj and newData required',
                    required: true,
                    sampleData: {
                        matchObj: {
                            id: 1,
                        },
                        newData: {
                            name: 'bhassi 2',
                            email: 'bhai@jnjk.com',
                        },
                    },
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },

        delete: {
            tags: ['Supabase'],
            summary: 'Delete Data',
            description: 'To delete a specific row from the table',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'tableName',
                    in: 'query',
                    description: 'Name of table',
                    type: 'string',
                    required: true,
                },
                {
                    name: "schema",
                    in: "query",
                    description: "Schema of table, defaults to 'public'",
                    type: "string"
                },
                {
                    name: 'type',
                    in: 'body',
                    description: 'An object to delete',
                    required: true,
                    sampleData: { id: 1 },
                },
            ],
            responses: {
                '200': {
                    description: 'successful operation',
                },
            },
        },
    };

    return schemaObj;
};
