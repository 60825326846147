import React, { useContext, useEffect, useState } from 'react';
import { BuilderContext, useDrawer } from 'react-flow-builder';
import { Form, Button, Input, Select } from 'antd';
import { AvailableWorkflows } from '../../../data/schema/workflows';
import { workflowList } from '../../../data/schema/workflows';
import TextArea from 'antd/lib/input/TextArea';

const ConfigForm = () => {
    const { selectedNode: node } = useContext(BuilderContext);
    const [workflowVal, setWorkflowVal] = useState("");
    const [selectedWorkFlow, setSelectedWorkFlow] = useState('')

    const { closeDrawer: cancel, saveDrawer: save } = useDrawer();

    const [form] = Form.useForm();

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            save?.(values);
        } catch (error) {
            const values = form.getFieldsValue();
            save?.(values, !!error);
        }
    };

    const onWorkFlowChange = async (workflow) => {
        setWorkflowVal(workflow);
        setSelectedWorkFlow(workflowList.workFlows[workflow])
    }

    useEffect(() => {
        if (node.data && node.data.step_name) {
            setWorkflowVal(node.data.step_name)
            setSelectedWorkFlow(workflowList.workFlows[node.data.step_name]);
        }
    }, [node?.data])

    return (
        <div>
            <Form form={form} onFinish={handleSubmit} initialValues={node.data || { name: node.name }} layout="vertical"
            >

                <Form.Item name="step_name" label="Step Name" rules={[{ required: true, message: "Step name is required" }]}>
                    <Select
                        options={AvailableWorkflows}
                        onChange={onWorkFlowChange}
                    />
                </Form.Item>
                {
                    workflowVal &&
                    selectedWorkFlow.map((item, index) => (
                        <div key={index}>
                            <>
                                <Form.Item
                                    key={item.endpoint}
                                    name="endpoint"
                                    label="Endpoint"
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder={item.endpoint} />
                                </Form.Item>
                                {item.parameters?.map((paramValue, paramIndex) => {
                                    if (paramValue.in !== 'body') {
                                        return (
                                            <Form.Item
                                                key={`${index}_${paramIndex}`}
                                                className='two-column'
                                                label={paramValue.name}
                                                name={paramValue.name}
                                                validateMessages=''
                                                rules={[
                                                    {
                                                        required: paramValue.required,
                                                    },
                                                ]}
                                                extra={
                                                    <div
                                                        style={{
                                                            fontWeight: 300,
                                                            fontSize: 14,
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: paramValue.description,
                                                        }}
                                                    />
                                                }
                                            >
                                                {paramValue.in !== 'body' &&

                                                    <Input
                                                        placeholder='Value'
                                                        style={{
                                                            borderRadius: 0,
                                                            padding: '4px 4px 5px 4px',
                                                        }}
                                                    />
                                                }

                                            </Form.Item>
                                        )
                                    }
                                    return null;


                                })}

                            </>
                        </div>
                    ))
                }
                <Form.Item>
                    <Button onClick={cancel} htmlType="button" style={{ marginRight: "14px" }}>Cancel</Button>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>

        </div >
    );
};

export default ConfigForm;

