import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Button, Empty, Popconfirm, Space, Table } from 'antd';
import moment from 'moment';
import { GlobalStateContext } from '../../context/GlobalContextProvider';

import '../../styles/applications.scss';
import axios from 'axios';

const WorkflowsList = () => {
    const state = useContext(GlobalStateContext);
    const { projects, selectedProjectId, user } = state;

    const [loading, setLoading] = useState(false);
    const [workflows, setWorkflows] = useState([]);


    const fetchWorkflows = async () => {
        try {
            setLoading(true);
            const { cloud_name } = user

            const response = await axios.get(
                `${process.env.GATSBY_BASE_URL}/workflows`,
                {
                    params: { projectId: selectedProjectId, cloud_name: cloud_name },
                    headers: { 'Content-Type': 'application/json' },
                }
            );
            const data = [];
            response.data.map((res) => {
                data.push({
                    workflow_name: res.workflow_name,
                    created_date: moment(res.created_date).format('dddd, MMM Do'),
                    updated_date: moment(res.updated_date).format('dddd, MMM Do'),
                    id: res._id,
                    key: res._id
                })
            })
            setWorkflows(data);
        } catch (error) {
            console.error("Error fetching workflows:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(async () => {
        await fetchWorkflows();
    }, [user, selectedProjectId]);

    const columns = [
        {
            title: "Workflows Name",
            dataIndex: "workflow_name",
            key: "workflow_name"
        },
        {
            title: "Created",
            dataIndex: "created_date",
            key: "created_date"
        },
        {
            title: "Updated",
            dataIndex: "updated_date",
            key: "updated_date"
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link to={`/workflows/${record.id}`}>Edit</Link>
                    <Popconfirm title="Are you sure" onConfirm={() => deleteWorkflow(record.id)} okText="Yes" cancelText="No"><a >Delete</a></Popconfirm>
                </Space>
            ),
        },
    ];


    const deleteWorkflow = async (id) => {

        const response = await axios.delete(
            `${process.env.GATSBY_BASE_URL}/workflows/${id}`,
            {
                headers: { 'Content-Type': 'application/json' },
            }
        );
        if (response.data) {
            await fetchWorkflows();
        }
    }

    return (
        <>
            <div className="page-content-header">
                <h2>Workflows</h2>
                <p>Workflows list</p>
            </div>

            <div>
                <Link to="/workflows/create">
                    <Button type="primay" >Add New Workflows</Button>
                </Link>
            </div>
            <br />
            <div className="project-edit" >
                <p>
                    <b>

                        <Table dataSource={workflows} columns={columns} />

                    </b>
                </p>

            </div>


        </>
    );
};

export default WorkflowsList;
