import React from 'react';
import { Router } from '@reach/router';


import AddWorkflows from '../blocks/components/addWorkflows'
import WorkflowsList from '../blocks/components/workflowsList';

import SEO from '../blocks/layout/seo';
import AppContainer from '../blocks/layout';

// markup
const Workflows = ({ location }) => {
    return (
        <>
            <SEO title="Workflows — NoCodeAPI" />
            <AppContainer activeNav="workflows" location={location}>
                <Router>
                    <WorkflowsList
                        path="/workflows"
                        location={location}
                    />
                    <AddWorkflows
                        path="/workflows/create"
                        location={location}
                    />
                    <AddWorkflows
                        path="/workflows/:workflowId"
                        location={location}
                    />
                </Router>
            </AppContainer>
        </>
    );
};

export default Workflows;
