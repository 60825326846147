import React from 'react';
import '@xyflow/react/dist/style.css';
import NodeForm from './workflowBuilder/builder';

const AddWorkflows = ({ location, workflowId }) => {

    return (
        <>

            <div className="page-content-header">
                <h2>Workflows</h2>
                <p>Build your workflows</p>
            </div>
            <div className="project-edit" >
                <p>
                    <b>
                        <NodeForm location={location} workflowId={workflowId} />
                    </b>
                </p>

            </div>

        </>
    );
};

export default AddWorkflows;
