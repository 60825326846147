export const workflowList = {
    workFlows: {
        airtable_multiple_record: [
            {
                endpoint: "https://v1.nocodeapi.com/{{cloud_name}}/airtable/{{token}}",
                description: "List all records from the table of your Airtable Base.",
                parameters: [
                    {
                        name: 'tableName',
                        in: 'query',
                        description: 'Name of your Airtable base table.',
                        required: true,
                        type: 'string',
                    },
                    {
                        name: 'fields',
                        in: 'query',
                        description:
                            'Only data for fields whose names are in this list will be included in the result. with seprated commas',
                        type: 'string',
                    },
                    {
                        name: 'id',
                        in: 'query',
                        description: 'record id',
                        type: 'string',
                    },
                    {
                        name: 'view',
                        in: 'query',
                        description: "By default is 'Grid view'",
                        required: false,
                        type: 'string',
                    },
                    {
                        name: 'perPage',
                        in: 'query',
                        description:
                            "Total number of records you want in one request.(0-100). <b>PRO users can get more than 100 records or pass 'all' to get all. Just pass perPage=all</b>",
                        required: false,
                        type: 'string',
                    },
                    {
                        name: 'sortBy',
                        in: 'query',
                        description: 'Field Name by which you wanna sort the response',
                        required: false,
                        type: 'string',
                    },
                    {
                        name: 'sortDirection',
                        in: 'query',
                        description: 'Sort the responce ascending or descending order',
                        required: false,
                        type: 'string',
                    },
                    {
                        name: 'filterByFormula',
                        in: 'query',
                        description:
                            'A formula used to filter records. Here is <a href="https://support.airtable.com/hc/en-us/articles/203255215-Formula-field-reference" target="_blank">list of formulas</a>',
                        required: false,
                        type: 'string',
                    },
                ]
            }
        ],
        airtable_store: [
            {
                endpoint: "https://v1.nocodeapi.com/{{cloud_name}}/airtable/{{token}}",
                description: "Create new Records",
                parameters: [
                    {
                        name: 'tableName',
                        in: 'query',
                        description: 'Name of your Airtable base table.',
                        required: true,
                        type: 'string',
                    },
                    {
                        name: 'typecast',
                        in: 'query',
                        description: 'To enable Automatic conversion. true or false',
                        type: 'boolean',
                    },

                ],
            }
        ],
        googlesheet_store: [
            {
                endpoint: "https://v1.nocodeapi.com/{{cloud_name}}/google_sheets/{{token}}/addRows",
                description: "Add multiple new rows into Google Sheet.",
                parameters: [
                    {
                        name: 'tabId',
                        in: 'query',
                        description: 'Your Sheet Tab name. (ex: Sheet1)',
                        required: true,
                    },

                ],
            }
        ],
        googlesheet_multiple_record: [
            {
                endpoint: "https://v1.nocodeapi.com/{{cloud_name}}/google_sheets/{{token}}",
                description: "Get the rows from your google sheet",
                parameters: [
                    {
                        name: 'tabId',
                        in: 'query',
                        description: 'Your Sheet Tab name. (ex: Sheet1)',
                        required: true,
                    },
                    {
                        name: 'row_id',
                        in: 'query',
                        description: 'If you want one row',
                        type: 'integer',
                    },
                    {
                        name: 'fields',
                        in: 'query',
                        description:
                            'Only data for fields whose names are in this list will be included in the result. with separated commas',
                        type: 'integer',
                    },
                    {
                        name: 'filterBy',
                        in: 'query',
                        description: 'key to filter result',
                    },
                    {
                        name: 'filterValue',
                        in: 'query',
                        description:
                            'text value to filter the rows, use multiple values to filter with separated commas',
                    },
                    {
                        name: 'filterType',
                        in: 'query',
                        description:
                            'How you want to filter: default is <b>equal</b>. Valid values are <b>equal</b>, <b>contain</b>, <b>greater</b> - only for integar, <b>less</b> - only for integar',
                    },
                    {
                        name: 'valueRenderOption',
                        in: 'query',
                        description:
                            'Determines how values should be rendered in the output. FORMATTED_VALUE, UNFORMATTED_VALUE or FORMULA',
                        type: 'string',
                    },
                ],
            }
        ],
        supabase_store: [
            {
                endpoint: "https://v1.nocodeapi.com/{{cloud_name}}/supabase/{{token}}",
                description: "To add new rows into the table",
                parameters: [
                    {
                        name: 'tableName',
                        in: 'query',
                        description: 'Name of table',
                        type: 'string',
                        required: true,
                    },
                    {
                        name: "schema",
                        in: "query",
                        description: "Schema of table, defaults to 'public'",
                        type: "string"
                    },
                    {
                        name: 'type',
                        in: 'body',
                        description: 'An array of object to add into table',
                        required: true,
                        sampleData: [
                            { name: 'james', email: 'james@example.com' },
                            { name: 'marry', email: 'marry@example.com' },
                        ],
                    },
                ],
            }
        ]
    }
}

export const AvailableWorkflows = [
    {
        label: "Airtable Get Multiple Record",
        value: "airtable_multiple_record"
    },
    {
        label: "Airtable Store",
        value: "airtable_store"
    },
    {
        label: "GoogleSheet Store",
        value: "googlesheet_store"
    },
    {
        label: "GoogleSheet Get Records",
        value: "googlesheet_multiple_record"
    },
    {
        label: "Supabase Store",
        value: "supabase_store"
    },
]